import { useCallback, useRef } from 'react';

import type { SanityTwoUpForm } from '@/lib/sanity/queries/twoUpForm';
import { cn } from '@/lib/utils';

import { FormCustom } from './FormCustom';
import { Headline } from './Headline';
import { Media } from './Media';
import { Overline } from './Overline';
import { RichText } from './RichText';

export function TwoUpForm(props: SanityTwoUpForm) {
  const hasHeadingAbove = props.display === 'headingAbove';
  const hasFormattedBodyCopy = Boolean(
    props.formattedBodyCopy && props?.formattedBodyCopy?.length,
  );

  const desktopFormClassNames = {
    right: {
      form: 'l:ml-auto',
      container: undefined,
    },
    left: {
      form: 'l:mr-auto',
      container: 'l:order-first',
    },
  }[props.desktopFormPosition];

  const isFirst = props.sectionIndex === 0;

  const ref = useRef<HTMLDivElement>(null);

  const scrollIntoView = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  const Heading = (
    <Headline
      value={props.headline}
      overline={props.overline}
      className="mb-25"
      variant={props.headlineSize === 'large' ? 2 : 4}
    />
  );

  const BodyContent = (
    <>
      {props.bodyCopy && !hasFormattedBodyCopy ? (
        <p className="text-body max-w-480 text-pretty">{props.bodyCopy}</p>
      ) : null}
      {hasFormattedBodyCopy ? (
        <RichText
          value={props.formattedBodyCopy ?? []}
          normalTextSizeClassName="text-body"
        />
      ) : null}
    </>
  );

  return (
    <div
      ref={ref}
      className={cn('p-main bg-grayscale-01 py-100', {
        'rounded-35 py-100': !isFirst,
        'mt-[calc(var(--header-height)*-1)] rounded-b-35 pb-100 pt-[calc(var(--header-height)+3rem)]':
          isFirst,
      })}
    >
      {hasHeadingAbove && (
        <div className="col-span-full max-w-600 l:max-w-1235 text-left mx-auto [&>*]:max-w-[30ch]">
          {Heading}
        </div>
      )}
      <div className="grid-main mx-auto max-w-600 l:max-w-1235">
        {!hasHeadingAbove && (
          <div className="col-span-full l:col-span-6">
            {Heading}
            {BodyContent}
          </div>
        )}
        <div
          className={cn(
            desktopFormClassNames.container,
            'col-span-full row-span-3 pt-32 l:col-span-6',
            {
              'pt-0': hasHeadingAbove,
            },
          )}
        >
          <div className={cn(desktopFormClassNames.form, 'l:max-w-480')}>
            {hasHeadingAbove && <div className="mb-20">{BodyContent}</div>}
            <FormCustom
              form={props.form}
              confirmation={props.confirmation}
              onSubmitSuccess={scrollIntoView}
              onSubmitError={scrollIntoView}
            />
          </div>
        </div>
        {props.additionalContent?.map((section) => {
          switch (section._type) {
            case 'image':
            case 'videoLoop':
              return (
                <div className="col-span-full mt-40 l:col-span-6">
                  <div className="mx-auto max-w-480 px-0 l:mx-0 l:px-20">
                    <Media
                      key={section._key}
                      media={section}
                      sizes={['600px', { l: '50vw', '1285px': '620px' }]}
                    />
                  </div>
                </div>
              );
            case 'logoWall':
              return (
                <div
                  key={section._key}
                  className="col-span-full mt-60 text-center l:col-span-6 l:text-left"
                >
                  {section.overline ? (
                    <Overline className="mb-30">{section.overline}</Overline>
                  ) : null}
                  <div className="l:justify-left flex flex-wrap items-center justify-center gap-x-20 gap-y-16 l:gap-x-36 l:gap-y-28">
                    {section.logos.map((item) => (
                      <Media
                        key={item._key}
                        media={item.logo}
                        className="object-contain"
                        style={{ width: item.logoWidth }}
                        sizes={`${item.logoWidth}px`}
                      />
                    ))}
                  </div>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
}
