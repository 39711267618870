import { forwardRef } from 'react';
import {
  Listbox as HeadlessListbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { Check } from 'lucide-react';
import { ControllerRenderProps } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { ChevronDown } from '@/components/ChevronDown';

export type ListboxOptions = Array<{
  label: string;
  value: string;
}>;

type Props = Partial<ControllerRenderProps> &
  React.InputHTMLAttributes<HTMLSelectElement> & {
    className?: string;
    displayValue?: string;
    options: ListboxOptions;
  };

export const Listbox = forwardRef<HTMLSelectElement, Props>(
  (
    {
      className,
      options,
      placeholder,
      value,
      displayValue,
      onChange,
      name,
      defaultValue,
    },
    ref,
  ) => {
    return (
      <div className={cn('relative', className)}>
        <HeadlessListbox
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          name={name}
          ref={ref}
          as={'div'}
        >
          <ListboxButton
            className={cn('w-full trigger', { 'text-grayscale-04': !value })}
          >
            {value !== '' ? displayValue ?? value : placeholder}
            <ChevronDown className="icon-indicator" />
          </ListboxButton>
          <ListboxOptions
            anchor={{
              to: 'bottom',
              gap: 6,
            }}
            transition
            className="w-[var(--button-width)] options-container"
          >
            {options.map((option) => (
              <ListboxOption
                className="group options-item"
                key={option.value}
                value={option.value}
              >
                <Check className={'options-item-checked'} />
                {option.label}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </HeadlessListbox>
      </div>
    );
  },
);

Listbox.displayName = 'Listbox';
